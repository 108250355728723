const latitude = 11.080646;
const longitude = 107.1064083;
const gMapUrl = 'https://maps.app.goo.gl/KbtTJcqFWphEQZon6';
const coords = [latitude, longitude];

const map = L.map('map', { zoomControl: false }).setView(coords, 12);
L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
  maxZoom: 19,
  attribution:
    '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
}).addTo(map);

L.marker(coords)
  .addTo(map)
  .bindPopup(
    L.popup({
      autoClose: false,
      closeOnClick: false,
    }),
  )
  .setPopupContent(`<a href=${gMapUrl}>Tổ Yến Phạm Gia<a/>`)
  .openPopup();
